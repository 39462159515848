<template>
  <div class="page">
    <Navbar title="帮助中心" />
    <van-image
      class="top"
      :src="require('../../../assets/images/seller/help-seller.jpg')"
    ></van-image>
    <Tabbar active="own" />
  </div>
</template>
<script>
import Navbar from '../../module/common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import { Image } from 'vant'
export default {
  components: {
    Navbar: Navbar,
    Tabbar: Tabbar,
    [Image.name]: Image
  },
  data () {
    return {

    }
  }
}
</script>
<style scoped>
.top {
  width: 100%;
}
</style>
